import React, { useState, useEffect, memo } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Box, Grid } from '@material-ui/core'

import _, { get } from 'lodash'
import { Route, Redirect } from 'react-router-dom'

import EditComponent from '../EntryListAndEdit/EditComponent.js'
import AddComponent from '../EntryListAndEdit/AddComponent.js'

const useStyles = makeStyles((theme) => ({}))

const PaginatedImageList = (props) => {
  const classes = useStyles()

  const auth = useSelector(state => state.auth)
  const [entries, setEntries] = useState([])
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(0)
  const [numPages, setNumPages] = useState(1)
  const [total, setTotal] = useState(0)
  const [pagelinks, setPagelinks] = useState([])
  const [searchterm, setSearchterm] = useState("")

  let list_get_url = props.list_get_url;
  let detail_get_url = props.detail_get_url;
  let detail_put_url = props.detail_put_url;
  let ledger_get_url = props.ledger_get_url;
  let add_new_url = props.add_new_url;
  let ledger_allow_entries = props.ledger_allow_entries;
  let show_machine_stats = props.show_machine_stats;

  let column_names = props.column_names;
  let header_names = props.header_names;

  let optional_column_names = props.optional_column_names;
  let optional_header_names = props.optional_header_names;

  let editable_fields = props.editable_fields;
  let non_editable_fields = props.non_editable_fields;

  useEffect(() => {
    const fetchListData = async () => {
      const config = { headers: { 'Content-Type': 'application/json' } }

      if (auth.access_token) {
        config.headers.Authorization = `Bearer ${auth.access_token}`
      }

      var results;
      if(searchterm == ""){
        results = await axios.get(list_get_url + page, config)
      }else{
        results = await axios.get(list_get_url + searchterm + "/" + page, config)
      }

      setTotal(results.data.total)
      setNumPages(results.data.pages)
      setPerPage(results.data.per_page)

      let these_pagelinks = [];
      for(var i=1; i<results.data.pages+1; i++){
        these_pagelinks.push(i)
      }
      setPagelinks(these_pagelinks);

      console.log(these_pagelinks);

      for (var i in results.data.items) {
        for (const [key, value] of Object.entries(results.data.items[i])) {
          if (key == 'balance' || key == 'donated' || key == 'earned redemption' || key == 'withdrawn') {
            results.data.items[i][key] = '$' + (results.data.items[i][key] / 1e6);
          }
          console.log(key, value);
        }
      }
      setEntries(results.data.items)
    }

    fetchListData();

    window.addEventListener('customertable_is_dirty', fetchListData);
  }, [page, searchterm])

  const onChangeSearchterm = (event) => {
    setSearchterm(event.target.value)
  };

  if (!auth.access_token) {
    return <Redirect to="/login" />
  }

  function createPaginationLinks(top){
    return <Grid container>
        <br/>
        <Grid sm={12} md={8}>
            {page > 1 &&
                <span onClick={() => setPage(page-1)}  style={{'marginRight':'5px', 'cursor':'pointer'}}>&lt;&lt;</span>
             }
             {page == 1 &&
                <span style={{'marginRight':'5px', 'opacity':0.3}}>&lt;&lt;</span>
             }

            {pagelinks.length <= 10 &&
                _.map(pagelinks, pl =>{
                    if(pl == page){
                        return <span style={{'marginRight':'5px', 'textDecoration':'underline', 'opacity':0.3}}>{pl}</span>
                    }else{
                        return <span onClick={() => setPage(pl)}  style={{'marginRight':'5px', 'cursor':'pointer'}}>{pl}</span>
                    }
                })
            }

            {pagelinks.length > 10 &&
                _.map(pagelinks, pl =>{
                    if(pl == page){
                        return <span style={{'marginRight':'5px', 'textDecoration':'underline', 'opacity':0.3}}>{pl}</span>
                    }else if(pl < (page+5) && pl > (page-5) ){
                        return <span onClick={() => setPage(pl)}  style={{'marginRight':'5px', 'cursor':'pointer'}}>{pl}</span>
                    }
                })
            }

            {page < numPages &&
                <span onClick={() => setPage(page+1)}  style={{'marginRight':'5px', 'cursor':'pointer'}}>&gt;&gt;</span>
             }
             {page == numPages &&
                <span style={{'marginRight':'5px', 'opacity':0.3}}>&gt;&gt;</span>
             }

            {page < numPages &&
                " ("+((page-1)*perPage+1) + "-" + page*perPage+" of "+total+")"
            }
            {page == numPages &&
                " ("+((page-1)*perPage+1) + "-" + total+" of "+total+")"
            }
        </Grid>
        <Grid sm={12} md={4}>

        {top &&
            <>
            <label htmlFor="searchterm" style={{'display':'inline-block', 'marginRight':'5px'}}>Search</label>
            <input type="text" name="searchterm" id="searchterm" defaultValue="" onChange={onChangeSearchterm} />
            </>
        }

        </Grid>
        <br/>
        </Grid>
  }

  return(<>
    {createPaginationLinks(true)}

    <table style={{'width':'100%'}}>
    <tr style={{'borderBottom':'2px solid gray'}}>
    {_.map(header_names, hn => {
        return <th style={{'padding':'10px'}}>{hn}</th>
    })}

    {window.innerWidth > 1080 &&
        _.map(optional_header_names, hn => {
        return <th style={{'padding':'10px'}}>{hn}</th>
    })
    }

    {detail_get_url !== undefined &&
        <th>Edit</th>
    }
        </tr>
        {_.map(entries, entry => {
            let options;
            if(entry['state'] == "CA"){
              options = {timeZone: "PST", timeZoneName: "short"}
            }else if(entry['state'] == "NY"){
              options = {timeZone: "EST", timeZoneName: "short"}
            }else{
              options = {timeZone: "UTC", timeZoneName: "short"}
            }
            return <tr style={{'borderBottom': '1px solid gray'}}>
                {_.map(column_names, cn => {
                  if(cn == 'patch_b64'){ // `Bearer ${auth.access_token}`
                      let img_str = "data:image/jpeg;base64, " + entry[cn];
                      return <td style={{'padding':'10px'}}><img src={img_str} /></td>
                    }else if(cn == 'timestamp'){
                      return <td style={{'padding': '10px'}}>{new Date(entry[cn]).toLocaleString("en-US", options)}</td>
                    }else{
                      return <td style={{'padding':'10px'}}>{entry[cn]}</td>
                      }
                })}

                {window.innerWidth > 1080 &&
                    _.map(optional_column_names, cn => {
                      return <td style={{'padding':'10px'}}>{entry[cn]}</td>
                })
                }

        {detail_get_url !== undefined &&
                <td><EditComponent
                    detail_get_url={detail_get_url}
                    detail_put_url={detail_put_url}
                    rowData={entry}
                    editable_fields={editable_fields}
                    non_editable_fields={non_editable_fields}
                    ledger_get_url={ledger_get_url}
                    ledger_allow_entries={ledger_allow_entries}
                    show_machine_stats={show_machine_stats}
                  /></td>
      }
            </tr>
        })
    }
    </table>

    {createPaginationLinks()}

    <AddComponent
        add_new_url={add_new_url}
        editable_fields={editable_fields}
      />

  </>)
}

export default PaginatedImageList