import React, { useState, useEffect, useReducer } from 'react'
import { ServerConfig } from 'config'


// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
//import List from '../../components/EntryListAndEdit/List'
import PaginatedImageList from '../../components/PaginatedImageList/PaginatedImageList'

const formReducer = (state, event) => {
  return {
    ...state,
    [event.name]: event.value
  }
}

const useStyles = makeStyles((theme) => ({}))


const DetectedUnknowns = () => {
  const non_editable_fields = [
    ["label", "Label"],
  ]

  const editable_fields = [];

  return (
    <>
      <PaginatedImageList
            list_get_url={`${ServerConfig.SERVER_URL}/admin/ai/detectedunknowns/`}
            detail_get_url={`${ServerConfig.SERVER_URL}/admin/ai/detectedunknowns/detail/`}
            detail_put_url={`${ServerConfig.SERVER_URL}/admin/ai/detectedunknowns/`}

            column_names={["timestamp", "id", "label", "mean_score", "patch_b64", "state", "belt_name"]}
            header_names={["TS", "id", "Label", "MeanScore", "Patch", "State", "Machine Name"]}

            optional_column_names={[]}
            optional_header_names={[]}

            editable_fields={editable_fields}
            non_editable_fields={non_editable_fields}
        />
    </>
  )

}

export default DetectedUnknowns